.titleSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  background-color:var(--category-color);
  padding: 24px 10px;
  border-radius: 10px 10px;
  position: relative;
  width: 100%;
}
  
.categoryTitle {
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
  margin: 12px 0;
  max-width: 300px;
}

.search-bar {
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: -13px;
  position: relative;
}
  
.search-input {
  width: 100%;
  border: 2px solid #A9AFAC;
  border-radius: 10px;
  padding: 8px 10px;
  margin: 0 10px;
}

.schemWrapper{
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
}

.categoryButton {
  margin: 8px 2px;
  width: 31%;
  color: #A9AFAC;
  font-size: 12px;
  padding: 6px 7px;
  font-weight: 300;
  border: 1px solid #A9AFAC;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}

.categorySelectButton {
  margin: 8px 2px;
  width: 43%;
  color: #A9AFAC;
  font-size: 12px;
  padding: 6px 7px;
  font-weight: 300;
  border: 1px solid #A9AFAC;
  border-radius: 6px;
  background-color: #fdfdfd;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
  position: absolute;
  top: 10px;
  right: 22px;
  color: #A9AFAC;
}
  
.filters {
  margin: 16px 0;
}

.categoryaplnBtn {
  width: 45%;
  margin-bottom: 15px;
}
.cardContent{
  padding: 10px 10px;
}

.categoryResultsTitle {
  color: #959494;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin: 20px 5px;
}
.categoryResults {
  padding-bottom: 30px;
  width: 100%;
}
  
.postschemeCard {
  border: 1px solid var(--category-color);
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 10px 10px 12px rgba(1, 2, 1, 0.1);
  flex: 0 0 calc(100% - 12px);
  cursor: pointer;
  min-height: 145px;
}
  
.CategoryscehemDescription {
  font-size: 12px;
  line-height: 1.3;
  color: #636363;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 120px; /* Adjust based on line height */
  position: relative;
  line-clamp: unset;
  padding-top: 5px;    
}
CategoryscehemDescription.expanded {
  -webkit-line-clamp: unset; /* Show all lines */
  max-height: none;
  line-clamp: unset; /* Remove max height restriction */
}

.expand-link {
  /* color: blue; */
  cursor: pointer;
  text-decoration: underline;
  position: absolute;
  bottom: 0;
  right: 0;
  background: white; /* Ensure background to avoid text overlap */
  padding: 0 5px;
}
  
.schemeTitle {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.schemeTitle::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 22%;
  border-bottom: 2px solid var(--border-color);
}
  
.stateSelectButton {
  margin: 8px 3px;
  width: 100%;
  color: #A9AFAC;
  font-size: 12px;
  padding: 6px 7px;
  font-weight: 300;
  /* border: 1px solid #A9AFAC; */
  border-radius: 6px;
  background-color: #fdfdfd;
}

.MuiPaper-elevation1 {
  box-shadow: none !important; 
} 

.container_narrow_category {
  max-width: 34rem;
  margin: 0 auto;
}

.bannerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bodySection {
  display: block;
}
.tagSection {
  border-bottom: 2px solid #F3F2F2;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.schemetitleborder {
  width: 10%;
}

@media only screen and (min-width: 769px) and (max-width: 2560px) {

  .container_narrow_category {
    display: unset;
    max-width: unset;
    margin: unset;
  }
  .search-bar {
    width: 80%;    
  }
  .bodySection {
    display: flex;
    padding-top: 16px;
  }
  .categoryButton {
    width: unset;    
  }
  .categorySelectButton {
    width: unset;
  }
  .tagSection {
    border-bottom: unset;
    display: flex;
    flex-direction: column;
    min-width: 180px;
  }
  .stateSelectButton {
    width: unset;
  }
  .filters {
    padding-right: 60px;
    margin-top: 30px;
  }
  .categoryResults {
    padding: 0px 60px 0px 60px;
  }
  .schemetitleborder {
    width: 6%;
  }

}





