.UserDashboardContainer {
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    overflow: hidden;
}
.infoportalbanner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
}
.headerBanner {
    padding-top: 80px;
}
.InfoportalUsercategories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    margin-top: 40px;
}
.bannerText {
    position: absolute;
    font-size: 26px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
}
.InfoPortalCategories {
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #fff;
    height: 120px;
    width: 120px;
    padding: 15px 15px;
    margin: 0 auto;
    box-shadow: 0px 0px 4.61px 0.92px #0000004A;
}
.InfoPortalCategories:hover {
    background-color: var(--hover-bg-color);
    color: #fff;
  }
.InfoportalIcon {
    max-width: 53%;
}
.infodashpage{
    background-color: rgb(249 249 249);
}

.CategoryName {
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    padding-top: 5px;
    margin-bottom: 0px;
    font-family: Inter;
    text-transform: capitalize;
    height: 6vh;
    
}
.carousel .carousel-status {
    display: none; 
}
.carousel .control-dots {
    display: none;
}
.carousel.carousel-slider .control-arrow{
    display: none;
}
@media (min-width: 768px) {
    .InfoportalUsercategories {
        grid-template-columns: repeat(4, 1fr);
    }
    .bannerText {
        font-size: 40px;
    }
}
@media (min-width: 1024px) {
    .InfoportalUsercategories {
        grid-template-columns: repeat(4, 1fr);
    }
    /* .CategoryName {
        font-size: 12px;
    } */
    .bannerText {
        font-size: 46px;
    }

}